//footer
$primaryColor: #eb3e32;
$hoverColor:#FB8F00;
.mainContainer{
    background-color: #fff;
    font-family: 'Poppins', sans-serif;
    min-height: 600px;
    flex:1;
    display: flex;
    align-items: center;
    justify-content: center;

}
.contactDiv{
    display: flex;
    width: 80%;
    flex-direction: column;
    padding: 30px 20px 50px 20px;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    margin-top: 50px;
    margin-bottom: 50px;
}
.footerSendDiv{
    background-color:#FB8F00;
     //width:200px;
    height:40px;
    margin-top:30px;
    display:flex;
    align-items:center;
    justify-content:center
}
.footerSendDiv:hover{
  cursor: pointer;
  background-color: $hoverColor;
}
.contactsInput{
    border-color:#000;
    border-width: 0px;
    margin-bottom:10px;
    height:40px;
    background-color:whitesmoke;
    color:"#fff";
    outline: none;
    padding-right: 10px;
    padding-left: 10px;
    
}
.catDiv{
    display: flex;
    justify-content: space-between;
}
.catDiv div{
    width:50%;
}
.contactsInput2{
    box-sizing: border-box;
    border-color:#000;
    border-width: 0px;
    margin-bottom:10px;
    height:40px;
    background-color:whitesmoke;
    color:"#fff";
    outline: none;
    
    padding-left: 10px;
    //flex:1
    width:90%;
}
.contactDiv h1{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 20px;
}
.contactDiv p{
    font-size: 14px;
}
.messageInput{
    font-size: 14px;
    border: none;
    flex: 1;  
    padding-top: 10px;
    padding-bottom: 10px;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    color: black;
    background-color:whitesmoke;
    min-height: 200px;
    padding-right: 10px;
    padding-left: 10px;
}


@media all and (max-width: 600px) {
.catDiv{
    display: flex;
    //justify-content: space-between;
    flex-direction: column;
}
.catDiv div{
    width:100%;
}
.contactsInput2{
    padding-right: 10px;
    padding-left: 10px;
    width:95%;
}}

@media all and (max-width: 420px) {
    .contactDiv{
        width: 90%;
        flex-direction: column;
        padding: 20px 10px 40px 10px;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .contactDiv h1{
        font-size: 22px;
    }
    .contactsInput2{
        padding-right: 10px;
        padding-left: 10px;
        width:100%;
    }
}
