

//footer
$primaryColor: #eb3e32;
$hoverColor:#FB8F00;
.footerDiv{
    width:100%;
    padding-bottom: 38px;
    //background-color :rgb(29, 134, 64);
    display: flex;
    padding-top: 30px;
    padding-left: 61px;
    box-sizing: border-box;
    overflow: hidden;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: 'Poppins', sans-serif;
}
.footerDivs{
     width: 25%;
    display: flex;
    padding-right: 38px;
    margin: 7px;
    flex-direction: column;
    //background-color: #FBCD00;
}
.contactsInput{
    border-color:#000;
    border-width: 0px;
    margin-top: 7px;
    width:80%;
    height:32px;
    background-color:#c3c3c3;
    color:"#fff";
    outline: none;
     padding-right: 7px;
    padding-left: 7px;
    
}
.ulLinkF{
    display: flex;
    align-items: center;
}
.ulLinkF p{
    font-size: 14px;
    margin-left: 5px;
    color: #fff;
}
.lineDivIc{
 padding-top: 5px;
}
.socialIcons{
    color:#fff;
    font-size: 22px;
    margin-right: 10px;
    cursor: pointer;
}
.lineDiv{
    
     padding-top:11px;
     border-top-style: solid;
     border-top-color: #76ad74;
     border-top-width:1px;
     display: flex;
     flex-direction: column;
    align-items: center;
}
.divCentreHead1{
    margin:0px;
    font-size:20px;
    font-weight:700;
    color:#fff;
}
.divCentreHead2{
    margin:0px;
    font-size:20px;
    font-weight:700;
    color:#fff;
}
.contactUs{
    margin:0px;
    font-size:20px;
    font-weight:700;
    color:#fff;
}
.ulFooter{
  padding: 0px;
  margin-top: 3px;
}
.ulFooter2{
    margin-top: 3px;
    //background-color: #6B6C6C;
    padding: 0px;
    }
.ulFooter2 li{
    padding: 0px;
    margin: 0px;
    list-style: none;
    text-align: start;
    margin-top: 11px;
}
 .FooterLi2{
    color: white;
    list-style: none;
    font-size:16px;
    font-weight: 600;
    text-decoration: none;   
 }
 .FooterLi{
    color: white;
    list-style: none;
    font-size:16px;
    font-weight: 600;
    text-decoration: none; 
}
.ulFooter li{
    margin-top: 11px;
    list-style: none;
    text-align: start;
}
.FooterLi:hover{
    cursor: pointer;
    color: $hoverColor;
    text-decoration:underline;
    padding-bottom: 3px;
}
.footerSendDiv{
    background-color:#FB8F00;
     width:153px;
     height:34px;
    margin-top:15px;
    display:flex;
    align-items:center;
    justify-content:center
}
.footerSendDiv:hover{
  cursor: pointer;
  background-color: $hoverColor;
}
.footerP{
    margin:0px;
    font-size:20px;
    font-weight:500;
    color:#fff;
    margin-top:30px;
}
.socialCont2{
    display: flex;
    margin-right: 50px;
}
@media all and (max-width: 923px) {
   
    .footerDivs{
        width: 40%;
    }
    .contactUs{
         margin-top: 15px;
        
    }
    .contactsInput{
        width: 100%;
    }
    
}
@media all and (max-width: 615px) {
   
    .footerDiv{
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        padding-left: 30px;
       
        
    }
    .footerDivs{
        width: 80%;
       
    }
    
    .footerSendDiv{
        width: 100%;
       
    }
   
    .contactsInput{
        width: 100%;
    }
    .ulFooter li{
        text-align: center;
    }
    .ulFooter2 li{
        text-align: center;
    }
    .ulFooter2{
        display: flex;
        flex-direction: column;
       
        justify-content:center;
        //align-self: center;
        align-items: center;
        //background-color:#000
    }
    .socialCont2{
        margin-right: 0px;
       
    }
}
@media all and (max-width: 500px) {

    .footerP{
        margin:0px;
        font-size:16px;
        margin-top:30px;
    }
}