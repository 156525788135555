$primaryColor: #FF7C59;
.mainContainer{
    background-color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    min-height: 600px;
    flex:1;
    padding:30px 5% 50px 5%

}
.mainContainer h3{
    text-align: center;
    font-size: 25px;
}
.mainContainer h2{
    font-size: 18px;
    color:$primaryColor;
}
.backImg{
    display: flex;
    flex-direction: column;
    height:300px;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ),url('https://images.pexels.com/photos/1000445/pexels-photo-1000445.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  }
  .backImg h3{
      color: #fff;
      font-size: 34px;
      font-weight: 500;
      padding-bottom: 10px;
  }
  .lineDiv{
    border-bottom: 4px solid #fff;
    width:100px;
  }
.div1 p{
margin-bottom: 20px;
font-size: 14px;
}
.div2 ul{
    margin-top: 10px;
}
.div2 ul li{
color:$primaryColor;
}
.div2 p{
    font-size: 14px;  
    margin-bottom:5px;
    
}
.mainContainer h4{
    text-align: center;
    font-size: 20px;
    margin-top:40px;
    margin-bottom: 40px;
}
.teamDiv{
    display: flex;
}
.causedivzoom{
    width:50%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px;  
    border: 2px solid #eee;
}
.causedivzoom img{
  width: 80%;
}
#P1{
    font-weight: 700;
    font-size: 20px;
    margin-top: 10px;
}
#P2{
    font-weight: 500;
    font-size: 18px;
    margin-top: -10px;
}
#P3{
    font-weight: 400;
    font-size: 14px;
    margin-top: -5px;
}

@media all and (max-width: 608px) {
    .imghoverzoom img{
      min-height: 38vh;
      height:230px;
    }
    .backImg{
      height:200px;
    }
    .backImg p{
    margin-left: 10%;
    margin-right: 10%;
     font-size: 14px;
    }
    .backImg h3{
      font-size: 20px;
      }
      #reqP{
        margin-bottom: 30px;  
        font-size: 20px;

      }
  }