//home donors
$primaryColor: #FBCD00;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}
.bodySection4{
  box-sizing: border-box;
    height: auto;
    overflow: hidden;
    //background-color: yellow;
    padding-bottom: 0px;
    //padding-top: 40px;
    position: relative;
    margin-bottom: 15px;
}

  .imghoverzoom:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  .donorsVolCont{
    display:flex;
    flex-direction:column;
   // align-items:center;
    width:100%;
   
  }
  .donorsVolCont h1{
    font-size: 20px;
    color: black;
    font-family: 'Poppins', sans-serif;
    line-height: 30px;
  }
  .donorsVolCont p{
   width: 70%;
   font-size: 16px;
   //margin-bottom: 5px;
   font-family: 'Poppins', sans-serif;
   color: #727272;
  }
  .donorItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
    position: relative;
    
  }
  .donorItemsCont{
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
    display:flex;
    flex:1;
  }
  .donorItemsCont::-webkit-scrollbar {
    width: 10px;
    background-color: #eee;
    height: 10px;
  }
  .donorItemsCont::-webkit-scrollbar-thumb {
    background-color: #dadada;

}
  .imghoverzoom{
    height:auto;
    min-width:350px;
   // width:50%;
    background-color:whitesmoke;
    margin:10px 20px 0px 0px;
    padding-bottom: 15px;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    margin-bottom: 10px;
   }
   .imghoverzoom img{
     width:100%;
     height:300px;
     min-height: 30vh;
     object-fit:cover;
     border-radius: 2px;
   }

   .imghoverzoom h1{
    padding-top:7px;
    font-size:16px;
    font-weight:700;
    padding-right:7px;
    padding-left:10px;
    margin:0;
    font-family: 'Poppins', sans-serif;
   // line-height: 0.8;
    color: black;
    -webkit-line-clamp: 2;
    @include maxlinelength();
   }
   .imghoverzoom h4{
    font-size:12px;
    padding-right:7px;
    font-weight:400;
    padding-left:10px;
    margin-top:0px;
     color:#808080;
     font-family: 'Poppins', sans-serif;
    line-height: 20px;
    -webkit-line-clamp: 1;
    @include maxlinelength();
   
  }
  .imghoverzoom p{
    font-size:14px;
    padding-right:7px;
    font-weight:500;
    padding-left:7px;
    margin-top:0px;
     color:$primaryColor;
     font-family: 'Poppins', sans-serif;
    // line-height: 0.5;
     -webkit-line-clamp: 1;
    @include maxlinelength();
  }
  .imghoverzoom h5{
    font-size:10px;
    padding-right:7px;
    font-weight:600;
    padding-left:7px;
    margin-top:-2px;
     color:rgb(151, 151, 151);
     font-family: 'Poppins', sans-serif;
    // line-height: 0.5;
     align-self: flex-end;
     -webkit-line-clamp: 1;
    @include maxlinelength();
  }
  .menuCont{
    box-sizing: border-box;
  display:flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 2%;
  align-items: center;
  }
  .menuCont h1{
    font-size: 20px;
  }
  .menuCont h2{
    font-size: 20px;
  }
  
  .menuCont h2:hover{
   cursor: pointer;
   color: $primaryColor;
  }
  .arrovDiv{
    position: absolute;
    height: 100%;
    z-index: 2;
    width:100%;
    top: 0;
    //padding-top: 7%;
    //margin-top: 17%;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
.arrovDivLeft{
 background-color: white;
 height: 30px;
 width: 30px;
 border-radius: 50px;
 margin-top: 5%;
}
.arrovDivRight{
  background-color: white;
  height: 30px;
 width: 30px;
 border-radius: 50px;
 margin-top: 5%;
}
.arrovDivLeft:hover{
  background-color: $primaryColor;
  color:white;
}
.arrovDivRight:hover{
  background-color: $primaryColor;
  color:white;
}
.modal{
  height: 100%;
  width:100%;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  background-color:#00000022;
  z-index: 9999;
  display:flex;
  align-items: center;
  justify-content: center;
}
.modalCont{
  height:300px;
  width:350px;
  box-sizing: border-box;
  background-color:#fff;
  border-radius: 10px;
  padding: 20px ;
}
.contDiv{
  display:flex;
  margin-top: 10px;
  align-items: center;
}
.contDiv p{
  margin-left: 5px;
  font-size: 16px;
}
.close{
  width:100%;
  background-color:#000;
  border-radius: 5px;
  margin-top: 20px;
  padding:5px 0px 5px 0px;
  color: #fff;
  text-align: center;
}
.close:hover{
  cursor: pointer;
}
.modalCont h5{
  font-size: 16px;
  margin-bottom: 5px;
}
   @media all and (max-width: 1200px) {
    .imghoverzoom{
      height:auto;
      width:31%;
      margin:10px 20px 10px 0px;
      padding-bottom: 20px;

     }
   }
   @media all and (max-width: 1000px) {
    .imghoverzoom{
      height:auto;
      width:30%;
      margin:10px 20px 10px 0px;
      padding-bottom: 20px;

     }
   }
  @media all and (max-width: 912px) {
    .menuCont h1{
      font-size: 20px;
    }
     
     .imghoverzoom{
      width:47%;
    }
    
    
}
@media all and (max-width: 800px) {
   .imghoverzoom{
    width:46%;
  }
  
  
}
@media all and (max-width: 608px) {
  .imghoverzoom img{
    min-height: 38vh;
    height:230px;
  }
  .donorItemsCont{
    flex-wrap: nowrap;
    overflow-x: auto;
    width: auto;
  }
  .imghoverzoom{
    height:auto;
    min-width:300px;
    margin:10px 20px 10px 0px;
    padding-bottom: 20px;
  //background-color:yellow
   }
   .arrovDivLeft{
    margin-top: 15%;
   }
   .arrovDivRight{
    margin-top: 15%;
   }

}
@media all and (max-width: 585px) {
  
   
     .donorsVolCont p{
      font-size: 14px;
      margin-bottom: 15px;
     }
.imghoverzoom{
 
  //width:47%;
  margin:3px;
 
 }
 .imghoverzoom img{
  width:100%;
  height:38vh;
  min-height:230px;
  
 }
 .imghoverzoom h1{
  font-size:19px;
 }
 .imghoverzoom h4{
  font-size:13px;
  
}
.imghoverzoom p{
  font-size:16px;
}
.imghoverzoom:hover {
  cursor: pointer;
  transform: scale(1.0);
}}

 @media all and (max-width: 494px) {
  .imghoverzoom{
    width:47%;
    margin:3px;
   
   }
 
   .imghoverzoom img{
    width:100%;
    min-height:190px;
    height: 31vh;
    
   }
  }
   @media all and (max-width: 400px) {
   
    .imghoverzoom{
      width:50%;
      margin:2px 10px 10px 1px;
      padding: 7px 7px 15px 7px;
      background-color: white;
      border: none;
      //box-shadow: none;
      
     }
     .imghoverzoom img{
      width:100%;
      min-height:180px;
      height: 30vh;
      
     }
     .donorsVolCont{
      display:flex;
      flex-direction:column;
      align-items:center;
      width:100%;
     
    }
    .donorsVolCont p{
     width: 80%;
     align-self: flex-start;
     text-align: left;
     margin-bottom: 10px;
     margin-top: 0px;
     font-size: 12px;
     padding-left: 0px;
     padding-right: 10px;
     
    }
    .imghoverzoom h1{
      font-size: 16px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top:2px;
     }
     .imghoverzoom h4{
      font-size:16px;
      padding-left: 0px;
      padding-right: 0px;
    
    }
    .imghoverzoom p{
      font-size:16px;
      padding-left: 0px;
      padding-right: 0px;
  
    }
    .imghoverzoom h5{
      font-size:10px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .donorItemsCont::-webkit-scrollbar {
      width: 0px;
      background-color: whitesmoke;
      height: 0px;
    }
    .donorItemsCont::-webkit-scrollbar-thumb {
      background-color: wheat;
    
  }
  .menuCont h1{
    font-size: 16px;
  }
  .menuCont h2{
    font-size: 16px;
  }
  .arrovDiv{
    display: none;
   }

   .modalCont{
    height:280px;
    width:300px;
    padding: 10px ;
  }
  .contDiv{
    display:flex;
    margin-top: 10px;
    align-items: center;
  }
  .contDiv p{
    margin-left: 5px;
    font-size: 14px;
  }
  .close{
    width:100%;
    margin-top: 30px;
    padding:2px 0px 2px 0px;
    color: #fff;
    text-align: center;
  }
  .modalCont h5{
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .book{
    font-size: 14px;
  }
    }
     @media all and (max-width: 350px) {
      
       .imghoverzoom img{
        width:100%;
        height:38vh;
        min-height: 230px;
       }
       .imghoverzoom h1{
        font-size: 20px;
       }
       .imghoverzoom h4{
        font-size:18px;
      
      }
      .imghoverzoom p{
        font-size:18px;
    
      }
      .donorsVolCont p{
        font-size: 12px;
       }
       .menuCont h1{
        font-size: 14px;
      }
      .menuCont h2{
        font-size: 14px;
      }
      }
