$primaryColor: #FF7C59;
.mainContainer{
    background-color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    min-height: 500px;
    flex:1;
    padding:30px 20px 50px 20px

}
.mainContainer h3{
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    margin-bottom:0px;
}
.mainContainer h4{
    text-align: center;
    margin-bottom: 20px;
    font-size: 20px;
    color: #adadad;
    font-weight: 500;
}
.contBottom3{
    display: flex;
    margin-top: 10px;
}
.contBottom3 div{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dadada;
    height: 200px;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
}
.servIcon{
    font-size: 50px;
    color: #5e8175;
}
.contBottom3 div p{
    padding-top: 20px;
}
@media all and (max-width: 650px) {
    .contBottom3{
        flex-direction: column;
     }
     .contBottom3 div{
        min-height: 130px;
        margin-bottom: 10px;
    }
}

@media all and (max-width: 420px) {
    .mainContainer{
        padding:30px 5px 50px 5px
    
    }
    .mainContainer h3{
        font-size: 20px;
    }
    .mainContainer h4{
        font-size: 16px;
    }
}
