$primaryColor: #FF7C59;
$hoverColor:#FB8F00;
.mainContainer{
    background-color: whitesmoke;
    font-family: 'Poppins', sans-serif;
    min-height: 600px;
    flex:1

}
.contBottom{
    padding-left: 2%;
    padding-right: 1%;
    }
    .servP1{
        text-align: center;
        font-size: 24px;
        color:#000;
        font-weight: 500;
        }
        .servP2{
            text-align: center;  
            font-size: 20px;
            margin-bottom: 20px;
            color:#adadad;
        }
        .backImg{
            display: flex;
            flex-direction: column;
            height:300px;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.5), 
                rgba(0, 0, 0, 0.5)
              ),url('https://images.pexels.com/photos/1543415/pexels-photo-1543415.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
          }
          .backImg h3{
              color: #fff;
              font-size: 34px;
              font-weight: 500;
              padding-bottom: 10px;
          }
          .lineDiv{
            border-bottom: 4px solid #fff;
            width:100px;
          }
          .backImg2{
            display: flex;
            flex-direction: column;
            height:600px;
            align-items: center;
            justify-content: center;
            margin-bottom: 40px;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: linear-gradient(
                rgba(0, 0, 0, 0.2), 
                rgba(0, 0, 0, 0.2)
              ),url('https://images.pexels.com/photos/59989/elephant-herd-of-elephants-african-bush-elephant-africa-59989.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
          }
          .contBottom2 h3{
              text-align: center;
              margin-top: 20px;
              font-size: 25px;
              font-weight: 600;
          }
          .contBottom2 h4{
            text-align: center;
            color:#adadad;
            margin-bottom:30px;
            font-weight: 500;
        }
        .reqDiv{
            display: flex;
            flex-direction: column;
            height:500px; 
            background-color: #fff;
            width: 50%;
            margin-left: 10%;
            margin-right: 10%;
            align-items: center;
            justify-content: center;
            
        }
        .reqDiv P{
            color:#000
        }
        .contInput{
            box-sizing: border-box;
            border-color:#000;
            border-width: 0px;
            margin-top: 7px;
            width:80%;
            height:40px;
            background-color:#eee;
            color:"#fff";
            outline: none;
             padding-right: 7px;
            padding-left: 7px;
            margin-bottom:10px;
            
        }
        .sendDiv{
            background-color:#FB8F00;
            height:40px;
            margin-top:15px;
            display:flex;
            align-items:center;
            justify-content:center;
            width:80%;
           
            
        }
        .sendDiv:hover{
          cursor: pointer;
          background-color: $hoverColor;
        }
        .messageInput{
            box-sizing: border-box;
            font-size: 14px;
            border: none;
            padding-top: 10px;
            padding-bottom: 10px;
            outline: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
            resize: none;
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
            color: black;
            background-color:whitesmoke;
            min-height: 100px;
            padding-right: 10px;
            padding-left: 10px;
            width:80%;
        }
        #reqP{
          margin-bottom: 30px;  
          font-size: 25px;
          font-weight: 500;
          color: #000;
        }

        @media all and (max-width: 608px) {
            .imghoverzoom img{
              min-height: 38vh;
              height:230px;
            }
            .backImg{
              height:200px;
            }
            .backImg p{
            margin-left: 10%;
            margin-right: 10%;
             font-size: 14px;
            }
            .backImg h3{
              font-size: 20px;
              }
              #reqP{
                margin-bottom: 30px;  
                font-size: 20px;

              }
          }
          @media all and (max-width: 420px) {
          .contBottom2 h3{
            font-size: 18px;
            margin-top: 0px;
            }
            .contBottom2 h4{
                font-size: 14px;
                margin-bottom: 20px;
            }
            .reqDiv{
                height:400px; 
                width: 90%;
                margin-left: 5%;
                margin-right: 5%;
            }
            .backImg2{
                height:480px;
              }




              .contInput{
                margin-top: 7px;
                width:90%;
                height:40px;
                margin-bottom:10px;
                
            }
            .sendDiv{
                height:40px;
                margin-top:15px;
                width:90%; 
            }
            .messageInput{
                padding-top: 10px;
                padding-bottom: 10px;
                min-height: 100px;
                padding-right: 10px;
                padding-left: 10px;
                width:90%;
            }
        }