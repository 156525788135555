//footer
$primaryColor: #eb3e32;
$hoverColor:#FB8F00;
.container{
    //padding-left: 5%;
    //padding-right: 5%;
    display: flex;
    flex-direction: column;
    background-color: whitesmoke;
    //padding-top: 30px;
}
.contBottom{
padding-left: 2%;
padding-right: 1%;
}
.searchTitle{
    margin-top: 30px;
    font-weight: 700;
    font-size: 20px;
    margin-left: 10px;
    color:black;
}
.searchTitle2{
    margin-top: 0px;
    font-weight: 600;
    font-size: 16px;
    margin-left: 10px;
    color:$hoverColor;
}
.contSearch{
    box-sizing: border-box;
    height: 60px;
    background-color: white;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 2px 0 rgba(0,0,0,0.05);
    margin-bottom: 5px;
    margin-right: 1%;
    display: flex;
    align-items: center;
    padding-left: 20px;
    margin-bottom: 40px;
}
.contSearch p{
    padding-left: 20px;
    font-size: 16px;
    color: #adadad;
    //font-weight: 400;
}
.contSearch:hover{
    cursor: text;
}
.contBottom2{
    padding-top: 50px;
    padding-bottom: 50px;
}
.contBottom3{
    display: flex;
}
.contBottom3 div{
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #dadada;
    height: 200px;
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
}
.servIcon{
    font-size: 50px;
    color: #5e8175;
}
.contBottom3 div p{
    padding-top: 20px;
    text-align: center;
}
.servP1{
text-align: center;
font-size: 24px;
color:#000;
font-weight: 500;
}
.servP2{
    text-align: center;  
    font-size: 20px;
    margin-bottom: 20px;
    color:#adadad;
}
.backImg{
    display: flex;
    flex-direction: column;
    height:250px;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ),url('https://images.pexels.com/photos/2044434/pexels-photo-2044434.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  }
  .backImg p{
  margin-left: 10%;
  margin-right: 10%;
  text-align: center;
  color: white;
  font-family: 'Poppins', sans-serif;
  }
  .backImg h3{
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    }
  .backImg2{
    height:350px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 40px;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(
        rgba(0, 0, 0, 0.5), 
        rgba(0, 0, 0, 0.5)
      ),url('https://images.pexels.com/photos/4017572/pexels-photo-4017572.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940');
  }
  .backImg2 p{
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    }
    .backImg2 h3{
      color: #fff;
      font-size: 30px;
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
      }
      @media all and (max-width: 650px) {
        .contBottom3{
            flex-direction: column;
         }
         .contBottom3 div{
            min-height: 130px;
            margin-bottom: 10px;
           
        }
    }
    .appCont1{
        background-color: #00000022;
        box-sizing: border-box;
        display:flex;
        flex-direction:row;
      align-items:center;
      justify-content: center;
    }
    #appCont2{
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #fff;
    //height: 300px;
    width:500px;
    border-radius: 10px;
    padding-left:30px;
    padding-right:30px;
    padding-top:20px;
    padding-bottom:20px;
    //justify-content: center;
    align-items: center;
    padding-top: 50px;
    position: relative;
  }
  #appCont2 p{
    align-self: flex-start;
  }
  .subInput{
    box-sizing: border-box;
    border-width: 0px;
    margin-bottom:10px;
    height:30px;
    background-color:whitesmoke;
    color:"#fff";
    outline: none;
    padding-right: 10px;
    padding-left: 10px;
    width:100%;
    
  }
  .subIc{
    font-size: 40px;
    color: #FB8F00;
  }
  .subP1{
    font-size: 18px;
    font-weight: 600;
  }
  .subP2{
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .subP3{
    background-color:#FB8F00;
    color: #fff;
    border-radius: 10px;
    padding:5px 20px;
    margin-top:20px;
  }
  .subP3:hover{
    //background-color:rgb(163, 56, 97);
    cursor: pointer;
  }
  .closeIc{
    font-size: 25px;
    color: #000;
    align-self: flex-end;
    position: absolute;
    top:0;
    right:0;
    margin-right: 20px;
    margin-top: 20px;
    //margin-bottom: 20px;
  }
  .closeIc:hover{
    color:#FB8F00;
    cursor: pointer;
  }
  .subTitle{
    font-size: 12px;
  }
  .footP{
    font-size: 12px;
    margin-top: 10px;
  }
@media all and (max-width: 600px) {
    .searchTitle{
        margin-top: 25px;
        font-weight: 700;
        font-size: 16px;
        margin-left: 10px;
    
    }
    .searchTitle2{
        margin-top: 0px;
        font-weight: 600;
        font-size: 14px;
        margin-left: 10px;
    }

    .backImg{
        height:200px;
      }
      .backImg p{
      margin-left: 10%;
      margin-right: 10%;
       font-size: 14px;
      }
      .backImg h3{
        font-size: 20px;
        }
        .backImg2{
            height:200px;
          }
          .backImg2 p{
          margin-left: 10%;
          margin-right: 10%;
           font-size: 14px;
          }
          .backImg2 h3{
            font-size: 20px;
            }
            
   

}

@media all and (max-width: 400px) {

    .container{
        padding-left: 0px;
        padding-right: 0px;
    }
    .contSearch{
        box-sizing: border-box;
        height: 50px;
        padding-left: 20px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
        margin-top: 20px;
    }
    .searchTitle{
        display: none;
    }
    .searchTitle2{
        display: none;
    }
    .contBottom{
        padding-left: 2%;
        padding-right: 2%;
        }
        .servP1{
            font-size: 18px;
            }
            .servP2{
                font-size: 14px;
                margin-bottom: 20px;
            }
            #appCont2{
              margin-right: 10px;
              margin-left: 10px;
              background-color: #fff;
              //height: 270px;
              width:95%;
              padding-left:15px;
              padding-right:15px;
              padding-bottom:20px;
              padding-top:30px;
            }
            .closeIc{
            margin-right: 10px;
            margin-top: 10px;
              }
}