
//shop nav
$primaryColor: #eb3e32;
$secondaryColor: #FED800;
$hoverColor:#FB8F00;
$shopColor:#bfaf80;
body{
    margin:0;
    padding: 0;
    //font-family: 'Poppins', sans-serif;
}

.shopNav{
    box-sizing: border-box;
    z-index: 3;
    //background-color:#fff;
    //border-bottom-width: 1px;
    //border-bottom-color:#eee;
    //border-bottom-style: solid;
    margin:0px;
    padding: 0px;
    align-items: center;
    position: absolute;
    width: 100%;
    top:0;right:0;left:0;
}
.shopNav0{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
#coName{
  font-size: 16px;
  font-weight: 600;
  color: #fff;  
  text-decoration: none;
  margin-left: 10px;
}
.shopNav2{
    width: 100%;
    box-sizing: border-box;
    height: auto;
    min-width: 66px;
    min-height: 66px;
    padding-left: 4%;
    //padding-right: 4%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    padding-bottom: 0px;
    margin-bottom: 0px;
   
}
.shopScrolled{
    width: 100%;
    position: fixed;
    background-color:#00000099;
    z-index: 3;
    //opacity: 0.7;
   
}

.shopNav3{
    margin-left: 50px;
    display: flex;
}

.NavLogoIcon{
  display: none;
}
.NavLogo img{
    height: 40px;
    border-radius: 50px;
    //margin-top: 5px;
}
.optionsIcon{
 color: #fff;
 font-size: 20px;
 margin-right: 5%;
 margin-left: 5px;
 display:none;
}
.optionsIcon:hover{
    cursor: pointer;
}
.ulLink{
    height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    
}

.ulLink2{
     height: 50px;
     display: flex;
     align-items: center;
     text-decoration: none;
     color: white;
     margin-right: 50px;
     
}
.ulLink2 h3{
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
}
.ulicon2{
    font-size: 30px;
}
.ulicon3{
    font-size: 20px;
}
.ulicon3:hover{
   transform: scale(1.1);
   cursor: pointer;
   color:$hoverColor;
}
.ulLink2:hover{
    color:$hoverColor;
}
.ulLink h3{
    margin-left: 10px;
    font-weight: 500;
    font-size: 16px;
    padding-right: 25px;
    padding-left: 20px;
    color:#fff;
    //border-right: solid #eee 1px;
}
.ulicon{
    font-size: 20px;
}
.ulLink:hover{
    color:$hoverColor;
}


.shopNav5{
    display: none;
}
.ulLink5{
    height: 40px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    padding-left: 4%;
    font-size: 14px;
   
}
.ulLink5:hover{
    background-color:#174905;
    color: #fff;
}
.ulLink5 h3{
    font-weight: 600;
    color:#fff
}
.optionsDiv{
    width:100%;
    display:flex;
    flex-direction: column;
    //height: 50px;
   
   //align-items: center;
   padding-bottom: 20px;
}
@media all and (max-width: 1300px) {
    .ulLink{
        margin-right: 30px;
    }
    .shopNav2{
        padding-left: 50px;
        padding-right: 0px;
    }
}
@media all and (max-width: 1200px) {
    .ulLink{
        margin-right: 30px;
    }
    .shopNav2{
        padding-left: 30px;
        padding-right: 0px;
    }
    .shopNav3{
        margin-left: 20px;
    }
    .ulLink h3{
        font-size: 14px;
    }
    .ulicon{
        font-size: 16px;
    }
    .ulLink2 h3{
        font-size: 14px;
    }
    .ulicon2{
        font-size: 25px;
    }
    .NavLogo img{
        height: 40px;
    }
    .ulLink{
        margin-right: 15px;
    }
 
    .ulLink2{
        margin-right: 30px;
        
   }
}
@media all and (max-width: 850px) {
   
  
    .shopNav5{
        
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        padding-left: 7%;
        padding-right: 7%;
       // background-color: #eb3e32;
    }
    
    
}
@media all and (max-width: 750px) {
    .shopNav3{
        display: none;
    }
    .optionsIcon{
        display:flex;
       }
}
@media all and (max-width: 600px) {

    .shopNav5{
        padding-left: 5%;
        padding-right: 5%;
        //background-color: #eb3e32;
    }
   
    .NavLogo img{
        height: 30px;
    }
    #coName{
        font-size: 15px;
        margin-left: 10px;
      }
    
}
@media all and (max-width: 500px) {
  .shopNav{
     border-bottom-width: 0px;
  }
  .shopNav2{
    padding-left: 10px;
    padding-right: 10px;
}
    .shopNav5{
        padding-left: 15px;
        padding-right: 15px;
        //background-color: rgb(255, 196, 0);
    }
    .ulLink h3{
        font-size: 14px;
    }
    .ulicon{
        font-size: 16px;
    }
    .NavLogoIcon{
        display: block;
    }
    
    .ulLink2 h3{
        display:none;
    }
}
@media all and (max-width: 400px) {
   
    .shopNav2{
        padding-left: 10px;
        padding-right: 10px;
        //min-height: 46px;
    }
    .shopNav5{
        padding-left: 15px;
        padding-right: 15px;
        //background-color: rgb(255, 196, 0);
    }
    .ulLink h3{
        font-size: 12px;
        margin-left: 5px;
        //padding-bottom: 0px;
    }
    .ulicon{
        font-size: 14px;
        //padding-bottom: 0px;
    }
    .NavLogo img{
        //height: 40px;
        
    }
    .socialCont{
        display:none;
    }
    .ulLink2 h3{
        display:none;
    }
    .ulicon2{
        font-size: 30px;
    }
   .shopNav5 .ulLink{
        height: auto;
        align-items: flex-start;
        margin-bottom: 10px;
        border-bottom: 2px solid #fff;
        padding-bottom: 3px;
        //background-color: #eb3e32;
    }
    .shopNav5 .ulLink:hover{
        border-bottom: 2px solid rgb(255, 196, 0);
    }
   .optionsIcon:hover{
       color: rgb(255, 196, 0);
   }
    
}
@media all and (max-width: 330px) {

    .shopNav5{
        padding-left: 10px;
        padding-right: 10px;
       
    }
    .ulLink h3{
        font-size: 10px;
        margin-left: 5px;
    }
    .ulicon{
        font-size: 12px;
    }
    
}