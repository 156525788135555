*{
  margin: 0;
 
}
html,body{
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}
body{

  font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }


  