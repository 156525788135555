//home donors
$primaryColor: #FB8F00;
@import url('https://fonts.googleapis.com/css2?family=Teko:wght@300;700&display=swap');
@mixin maxlinelength {
  overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
}
.bodySection4{
  box-sizing: border-box;
    height: auto;
    overflow: hidden;
    background-color: whitesmoke;
    padding-bottom: 30px;
    padding-top: 0px;
}

  .imghoverzoom:hover {
    cursor: pointer;
    transform: scale(1.03);
  }
  .donorsVolCont{
    display:flex;
    flex-direction:column;
   // align-items:center;
    width:100%;
   
  }
  .donorsVolCont h1{
    font-size: 20px;
    color: black;
    font-family: 'Poppins', sans-serif;
    line-height: 30px;
  }
  .donorsVolCont p{
   width: 70%;
   font-size: 16px;
   margin-bottom: 5px;
   font-family: 'Poppins', sans-serif;
   color: #727272;
  }
  .donorItemsCont{
    display:flex;
    flex:1;
    flex-wrap: wrap;
   
   // justify-content: space-evenly;

  }
  .imghoverzoom{
    height:auto;
    width:23%;
    background-color:#F9F8F8;
    margin:10px 20px 10px 0px;
    padding-bottom: 25px;
    transition: transform .5s ease;
    box-shadow: 0 3px 6px 0 rgba(0,0,0,0.2);
    border-radius: 5px;
   }
   .imghoverzoom img{
     width:100%;
     height:240px;
     min-height: 40vh;
     object-fit:cover
   }

   .imghoverzoom h1{
    padding-top:7px;
    font-size:16px;
    font-weight:500;
    padding-right:7px;
    padding-left:7px;
    margin:0;
    font-family: 'Poppins', sans-serif;
    line-height: 20px;
    color: black;
    -webkit-line-clamp: 2;
    @include maxlinelength();
   // background-color: #ff3300;
   }
   .imghoverzoom h4{
    font-size:14px;
    padding-right:7px;
    font-weight:700;
    padding-left:7px;
    padding-top:0px;
    color:#808080;
    font-family: 'Poppins', sans-serif;
    // background-color: aqua;
    -webkit-line-clamp: 1;
    line-height: 18px;
    @include maxlinelength();
  }
  .det1 p{
    font-size:14px;
    padding-right:7px;
    font-weight:500;
    padding-left:7px;
    margin-top:0px;
     color:#fff;
     font-family: 'Poppins', sans-serif;
    // line-height: 0.5;
     -webkit-line-clamp: 1;
    @include maxlinelength();
  }
  .det3{
    font-size: 12px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
  }
  .det5{
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    //background-color:$primaryColor;
    width:70px;
    height:30px;
    margin-left:10px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    color:$primaryColor;
    font-weight: 500;
    justify-content:center;
    border: 1px solid $primaryColor;
    letter-spacing: 1px;
  }
  .imghoverzoom h5{
    font-size:10px;
    padding-right:7px;
    font-weight:600;
    padding-left:7px;
    margin-top:-2px;
     color:rgb(151, 151, 151);
     font-family: 'Poppins', sans-serif;
    // line-height: 0.5;
     align-self: flex-end;
     -webkit-line-clamp: 1;
    @include maxlinelength();
  }
  .menuCont{
    box-sizing: border-box;
  display:flex;
  width: 100%;
  justify-content: space-between;
  padding-right: 2%;
  align-items: center;
  }
  .menuCont2{
    display:flex;
    align-items: center;
  }
  .menuIcon{
    color: #FB8F00;
    font-size: 20px;
  }
  .menuCont h1{
    font-size: 20px;
    color: #FB8F00;
    margin-left: 10px;
  }
  .menuCont h2{
    font-size: 20px;
  }
  
  .menuCont h2:hover{
   cursor: pointer;
   color: $primaryColor;
  }
  #mostPopP{
    font-size: 14px;
  }
  .modal{
    height: 100%;
    width:100%;
    position: fixed;
    top:0;
    bottom:0;
    left:0;
    background-color:#00000022;
    z-index: 9999;
    display:flex;
    align-items: center;
    justify-content: center;
  }
  .modalCont{
    height:300px;
    width:350px;
    box-sizing: border-box;
    background-color:#fff;
    border-radius: 10px;
    padding: 20px ;
  }
  .contDiv{
    display:flex;
    margin-top: 10px;
    align-items: center;
  }
  .contDiv p{
    margin-left: 5px;
    font-size: 16px;
  }
  .close{
    width:100%;
    background-color:#000;
    border-radius: 5px;
    margin-top: 20px;
    padding:5px 0px 5px 0px;
    color: #fff;
    text-align: center;
  }
  .close:hover{
    cursor: pointer;
  }
  .modalCont h5{
    font-size: 16px;
    margin-bottom: 5px;
  }
.det1{
  background-color:#5e8175;
  height:50px;
  margin-top: -7px;
  display:flex;
  align-items: center;
}
   @media all and (max-width: 1200px) {
    .imghoverzoom{
      height:auto;
      width:31%;
      margin:10px 20px 10px 0px;
      padding-bottom: 20px;

     }
   }
   @media all and (max-width: 1000px) {
    .imghoverzoom{
      height:auto;
      width:30%;
      margin:10px 20px 10px 0px;
      padding-bottom: 20px;

     }
   }
  @media all and (max-width: 912px) {
    .menuCont h1{
      font-size: 20px;
    }
   
     .imghoverzoom{
      width:47%;
    }
    
    
}
@media all and (max-width: 800px) {
   .imghoverzoom{
    width:46%;
  }
  
  
}
@media all and (max-width: 608px) {
  .imghoverzoom img{
    min-height: 38vh;
    height:230px;
  }
 
    
}

@media all and (max-width: 585px) {
  
   
     .donorsVolCont p{
      font-size: 14px;
      margin-bottom: 15px;
     }
.imghoverzoom{
 
  width:47%;
  margin:3px;
 
 }
 .imghoverzoom img{
  width:100%;
  height:38vh;
  min-height:230px;
  
 }
 .imghoverzoom h1{
  font-size:19px;
 }
 .imghoverzoom h4{
  font-size:13px;
  
}
.det1 p{
  font-size:16px;
}
.imghoverzoom:hover {
  cursor: pointer;
  transform: scale(1.0);
}
.menuCont h1{
  font-size: 16px;
}
.menuCont h2{
  font-size: 16px;
}
}

 @media all and (max-width: 494px) {
  .imghoverzoom{
    width:47%;
    margin:3px;
   
   }
 
   .imghoverzoom img{
    width:100%;
    min-height:190px;
    height: 31vh;
    
   }}
   @media all and (max-width: 380px) {
  
    .imghoverzoom{
      width:100%;
      margin:2px 1px 10px 1px;
      padding: 0px 0px 15px 0px;
      background-color: white;
      border: none;
      
     }
     .imghoverzoom img{
      width:100%;
      min-height:250px;
      height: 45vh;
      
     }
     .donorsVolCont{
      display:flex;
      flex-direction:column;
      align-items:center;
      width:100%;
     
    }
    .donorsVolCont p{
     width: 80%;
     text-align: left;
     margin-bottom: 10px;
     margin-top: 5px;
     font-size: 14px;
     padding-left: 0px;
     padding-right: 10px;
     align-self: flex-start;
     
    }
    .imghoverzoom h1{
      font-size: 18px;
      padding-left: 0px;
      padding-right: 0px;
     }
     .imghoverzoom h4{
      font-size:16px;
      padding-left: 0px;
      padding-right: 0px;
    
    }
    .det1 p{
      font-size:16px;
    }
    .imghoverzoom h5{
      font-size:10px;
      padding-left: 0px;
      padding-right: 0px;
    }
    #mostPopP{
      font-size: 12px;
    }



    .modalCont{
      height:280px;
      width:300px;
      padding: 10px ;
    }
    .contDiv{
      display:flex;
      margin-top: 10px;
      align-items: center;
    }
    .contDiv p{
      margin-left: 5px;
      font-size: 14px;
    }
    .close{
      width:100%;
      margin-top: 30px;
      padding:2px 0px 2px 0px;
      color: #fff;
      text-align: center;
    }
    .modalCont h5{
      font-size: 16px;
      margin-bottom: 5px;
      margin-top: 10px;
    }
    .book{
      font-size: 14px;
    }
  





    }
     @media all and (max-width: 342px) {
      
       .imghoverzoom img{
        width:100%;
        height:38vh;
        min-height: 230px;
       }
       .imghoverzoom h1{
        font-size: 18px;
       }
       .imghoverzoom h4{
        font-size:16px;
      
      }
      .det1 p{
        font-size:18px;
    
      }
      .donorsVolCont p{
        font-size: 14px;
       }
      }
